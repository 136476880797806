import { IconChevronRight } from '@tabler/icons-react';
import { Link, LinkProps } from '@tanstack/react-router';
import React from 'react';

import Conditional from '@/components/Conditional/Conditional';

import styles from './breadcrumb.module.css';

interface BreadcrumbProperties {
	links: LinkProps[];
}
export const Breadcrumb: React.FC<BreadcrumbProperties> = ({ links }) => (
	<div className={styles.container}>
		{links.map((link, index) => (
			<React.Fragment key={index}>
				<Link {...link} className={styles.link}>
					{link.children}
				</Link>
				<Conditional visible={index !== links.length - 1}>
					<IconChevronRight className={styles.separator} />
				</Conditional>
			</React.Fragment>
		))}
	</div>
);

export default Breadcrumb;
