import { IconCheck } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React from 'react';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/Accordion/Accordion';
import { FacetItem } from '@/customTypes/environment';

import styles from './SelectFacet.module.css';

interface SelectFacetProperties {
	className?: string;
	config?: FacetItem;
	from: '/category/$' | '/search';
	onChange?: () => void;
	search: Record<string, string | undefined>;
	url: string;
	values: {
		label: string;
		url: string;
	}[];
}

const SelectFacet: React.FC<SelectFacetProperties> = ({
	className,
	config,
	from,
	onChange,
	search,
	url,
	values,
}) => {
	const selectedValue = search[url];
	const selectedValues = typeof selectedValue === 'string' ? selectedValue.split(',') : [];

	if (values.length === 0) {
		return null;
	}

	const getLink = ({ label, url: valueURL }: { label: string; url: string }) => (
		<Link
			className={styles.option}
			from={from}
			key={valueURL}
			onClick={onChange}
			params={{}}
			search={search => {
				if (selectedValues.includes(valueURL)) {
					return {
						...search,
						p: undefined,
						[url]: selectedValues.filter(value => value !== valueURL).join(',') || undefined,
					};
				}
				if (search[url]) {
					return { ...search, p: undefined, [url]: search[url] + ',' + valueURL };
				}
				return { ...search, p: undefined, [url]: valueURL };
			}}
			to={from}
		>
			{selectedValues.includes(valueURL) ? (
				<IconCheck className={styles.check} />
			) : (
				<span className={styles.check} />
			)}
			{label}
		</Link>
	);

	return (
		<Accordion collapsible={true} defaultValue={config?.name ?? url} type="single">
			<AccordionItem
				className={`${styles.container} ${className ?? ''}`}
				title={config?.name ?? url}
				value="description"
			>
				<AccordionTrigger>{config?.name ?? url}</AccordionTrigger>
				<AccordionContent>
					{values
						.sort((a, b) =>
							selectedValues.includes(a.url) ? (selectedValues.includes(b.url) ? 0 : -1) : 1,
						)
						.map(value => getLink(value))}
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};

export default SelectFacet;
