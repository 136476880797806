import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DependencyList, useCallback } from 'react';

import { Line, Order } from '@/customTypes/Order';
import { deepMerge } from '@/utils/functions.ts';
import { parseItem } from '@/utils/Item.ts';
import { customFetch } from '@/utils/network';

const parseOrder = (order: Order): Order => {
	for (const line of order.lines) {
		// @ts-expect-error
		line.item = parseItem(line.item);
	}
	return order;
};

export const orderQueryOptions: UseQueryOptions<Order, unknown, Order> = {
	queryFn: async () => {
		const order = await customFetch<Order>({
			url: '/services/LiveOrder.Service.ss',
		});
		return parseOrder(order);
	},
	queryKey: ['order'],
};

// Fetches a slice of the current order and re-renders when the slice changes
// If your selector function depends on other variables, you can pass them as a second argument
export const useOrderQuery = <T>(
	selector: (order?: Order) => T,
	selectorDeps: DependencyList = [],
) => {
	const query = useQuery<Order, unknown, T>({
		...orderQueryOptions,
		notifyOnChangeProps: ['data'],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		select: useCallback(selector, selectorDeps),
	});
	return query.data as T;
};

export const useOrderLoading = () => {
	const query = useQuery({
		...orderQueryOptions,
		notifyOnChangeProps: ['isLoading'],
	});
	const dataQuery = useQuery({
		...orderQueryOptions,
		notifyOnChangeProps: ['data'],
		select: useCallback((data: Order) => data.loading, []),
	});
	return query.isLoading || dataQuery.data;
};
// TODO we may not need to deepmerge here
// TODO make all mutations modify the loading state
export const useUpdateOrderMutation = () => {
	const queryClient = useQueryClient();
	const initialData = queryClient.getQueryData<Order>(orderQueryOptions.queryKey);

	return useMutation<Order, unknown, Partial<Order>>({
		mutationFn: async newOrder => {
			const order = await customFetch<Order>({
				body: deepMerge(initialData!, newOrder),
				method: 'PUT',
				url: '/services/LiveOrder.Service.ss',
			});
			return {
				...parseOrder(order),
				loading: false,
			};
		},
		onError: () => {
			queryClient.setQueryData(orderQueryOptions.queryKey, initialData);
		},
		onMutate: async mutatedOrder => {
			await queryClient.cancelQueries({ queryKey: orderQueryOptions.queryKey });

			if (initialData) {
				queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
					...deepMerge(initialData, mutatedOrder),
					loading: true,
				});
			}
		},
		onSuccess: data => {
			queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
				...data,
				loading: false,
			});
		},
	});
};

// Add lines to the order with optimistic updates
export const useAddLinesMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<
		Order,
		unknown,
		{ item: { internalid: number; itemtype: string }; quantity: number }[],
		{ previousOrder?: Order }
	>({
		mutationFn: async lines => {
			queryClient.setQueryData(orderQueryOptions.queryKey, {
				...queryClient.getQueryData(orderQueryOptions.queryKey),
				loading: true,
			});
			const order = await customFetch<Order>({
				body: lines,
				method: 'POST',
				url: '/services/LiveOrder.Line.Service.ss',
			});
			return {
				...parseOrder(order),
				loading: false,
			};
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(orderQueryOptions.queryKey, context?.previousOrder);
		},
		onMutate: async newLines => {
			await queryClient.cancelQueries({ queryKey: orderQueryOptions.queryKey });

			const previousOrder = queryClient.getQueryData<Order>(orderQueryOptions.queryKey);

			if (previousOrder) {
				const temporaryLines = newLines.map((line, index) => ({
					...line,
					internalid: `temp-${Date.now()}-${index}`, // Temporary ID
				}));

				const newOrder = {
					...previousOrder,
					lines: [...previousOrder.lines, ...temporaryLines] as Line[],
				};

				queryClient.setQueryData<Order>(orderQueryOptions.queryKey, newOrder);
			}

			return { previousOrder };
		},
		onSuccess: data => {
			queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
				...data,
				loading: false,
			});
		},
	});
};

// Remove a line from the order with optimistic updates
export const useRemoveLineMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<Order, unknown, string, { previousOrder?: Order }>({
		mutationFn: async id => {
			const order = await customFetch<Order>({
				method: 'DELETE',
				parameters: { internalid: id },
				url: '/services/LiveOrder.Line.Service.ss',
			});
			return {
				...parseOrder(order),
				loading: false,
			};
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(orderQueryOptions.queryKey, context?.previousOrder);
		},
		onMutate: async id => {
			await queryClient.cancelQueries({ queryKey: orderQueryOptions.queryKey });

			const previousOrder = queryClient.getQueryData<Order>(orderQueryOptions.queryKey);

			if (previousOrder) {
				const newOrder = {
					...previousOrder,
					lines: previousOrder.lines.filter(line => line.internalid !== id),
				};
				queryClient.setQueryData<Order>(orderQueryOptions.queryKey, newOrder);
			}

			return { previousOrder };
		},
		onSuccess: data => {
			queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
				...data,
				loading: false,
			});
		},
	});
};

// Update a line in the order with optimistic updates
export const useUpdateLineMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<Order, unknown, Line, { previousOrder?: Order }>({
		mutationFn: async line => {
			queryClient.setQueryData(orderQueryOptions.queryKey, {
				...queryClient.getQueryData(orderQueryOptions.queryKey),
				loading: true,
			});
			const order = await customFetch<Order>({
				body: line,
				method: 'PUT',
				parameters: { internalid: line.internalid },
				url: '/services/LiveOrder.Line.Service.ss',
			});
			return {
				...parseOrder(order),
				loading: false,
			};
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(orderQueryOptions.queryKey, context?.previousOrder);
		},
		onMutate: async mutatedLine => {
			await queryClient.cancelQueries({ queryKey: orderQueryOptions.queryKey });

			const previousOrder = queryClient.getQueryData<Order>(orderQueryOptions.queryKey);

			if (previousOrder) {
				const newOrder = {
					...previousOrder,
					lines: previousOrder.lines.map(line =>
						line.internalid === mutatedLine.internalid ? mutatedLine : line,
					),
				};
				queryClient.setQueryData<Order>(orderQueryOptions.queryKey, newOrder);
			}

			return { previousOrder };
		},
		onSuccess: data => {
			queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
				...data,
				loading: false,
			});
		},
	});
};

export const usePlaceOrderMutation = () => {
	const queryClient = useQueryClient();
	const initialData = queryClient.getQueryData<Order>(orderQueryOptions.queryKey);

	return useMutation<Order>({
		mutationFn: async () =>
			parseOrder(
				await customFetch<Order>({
					body: initialData!,
					method: 'POST',
					url: '/services/LiveOrder.Service.ss',
				}),
			),
		onMutate: async () => {
			await queryClient.cancelQueries({ queryKey: orderQueryOptions.queryKey });

			if (initialData) {
				queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
					...initialData!,
					loading: true,
				});
			}
		},
		onSuccess: data => {
			queryClient.setQueryData<Order>(orderQueryOptions.queryKey, {
				...data,
				loading: false,
			});
		},
	});
};
