import { IconLoader2 } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';

import Conditional from '@/components/Conditional/Conditional';
import { Item } from '@/customTypes/Item';
import { getImageWithOptions } from '@/utils/Item';

import styles from './ImageShower.module.css';

const ImageShower: React.FC<{
	className?: string;
	item?: Item;
	mouseInside: boolean;
	options?: Record<string, string | undefined>;
}> = ({ className, item, mouseInside, options }) => {
	const [mouseEntered, setMouseEntered] = useState(false);
	const imageInterval = 750;
	const images = useMemo(() => getImageWithOptions(item, options), [item, options]);
	const [currentIndex, setCurrentIndex] = useState(0);

	// Handle mouse enter and leave from parent
	useEffect(() => {
		if (mouseInside) {
			setMouseEntered(true);
		} else {
			setCurrentIndex(0);
		}
	}, [mouseInside]);

	// Change from one image to the next while hovered
	useEffect(() => {
		if (mouseInside) {
			const interval = setInterval(() => {
				setCurrentIndex(previousIndex => {
					if (previousIndex < images.length - 1) {
						return previousIndex + 1;
					} else {
						clearInterval(interval);
						return previousIndex;
					}
				});
			}, imageInterval);

			return () => clearInterval(interval);
		} else {
			setCurrentIndex(0);
		}
	}, [images.length, mouseInside, imageInterval]);

	const progressBarWidth =
		images.length > 1 ? `${((currentIndex + 1) / (images.length - 1)) * 100}%` : '0%';

	return (
		<div
			className={`${className ?? ''} ${mouseInside ? styles.hover : ''}`}
			style={{ overflow: 'hidden', position: 'relative' }}
		>
			<div
				className={styles.container}
				style={{ transform: `translateX(-${currentIndex * 100}%)` }}
			>
				<Conditional visible={!item}>
					<IconLoader2 className={`${styles.loaderIcon} loader`} />
				</Conditional>
				{images.slice(0, mouseEntered ? images.length : 1).map((image, index) => (
					<img alt={image} className={styles.image} key={index} src={image} />
				))}
			</div>
			<div
				className={`${styles.progressBarContainer} ${
					mouseInside && images.length > 1 ? styles.hover : ''
				}`}
			>
				<div className={styles.progressBar} style={{ width: progressBarWidth }} />
			</div>
		</div>
	);
};

export default ImageShower;
