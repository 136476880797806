import { IconHome } from '@tabler/icons-react';
import { createFileRoute } from '@tanstack/react-router';
import { Helmet } from 'react-helmet-async';

export const Route = createFileRoute('/')({
	component: () => (
		<div>
			Hola buenas soy la
			<IconHome color="black" size={100} />
			<Helmet>
				<title>Home</title>
			</Helmet>
		</div>
	),
});
