import { IconX } from '@tabler/icons-react';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

import BooleanFacet from '@/components/BooleanFacet/BooleanFacet';
import Button from '@/components/Button/Button';
import NumberFacet from '@/components/NumberFacet/NumberFacet';
import SelectFacet from '@/components/SelectFacet/SelectFacet';
import { RangeFacetType, SelectFacetType } from '@/customTypes/Item';
import { useConfigQuery } from '@/stores/ConfigHooks.ts';
import features from '@/vendor/features.ts';

import styles from './Facets.module.css';

interface FacetsProperties {
	className?: string;
	facets?: (RangeFacetType | SelectFacetType)[];
	from: '/category/$' | '/search';
	open: boolean;
	setOpenFacets: React.Dispatch<React.SetStateAction<boolean>>;
	sort?: string;
}

const Facets: React.FC<FacetsProperties> = ({
	className,
	facets,
	from,
	open,
	setOpenFacets,
	sort,
}) => {
	const navigate = useNavigate();
	const facetsConfig = useConfigQuery(config => config?.facets);
	const defaultOption = features.search.sortOptions.find(option => option.default);
	const search = useSearch({
		strict: false,
	});
	const facetsElements = useMemo(
		() =>
			facets?.map(facet => {
				const facetConfig = facetsConfig?.find(facetConfig => facetConfig.id === facet.id);

				if (
					Object.prototype.hasOwnProperty.call(facet, 'min') &&
					Object.prototype.hasOwnProperty.call(facet, 'max')
				) {
					const parsedFacet = facet as RangeFacetType;
					return (
						<NumberFacet
							from={from}
							key={facet.id}
							max={parsedFacet.max}
							min={parsedFacet.min}
							name={facet.id}
							search={search}
							url={facet.url}
						/>
					);
				}

				const parsedFacet = facet as SelectFacetType;
				if (facet.values[0]?.url === 'false' && facet.values[1]?.url === 'true') {
					return (
						<BooleanFacet
							config={facetConfig}
							from={from}
							key={facet.id}
							onChange={() => setOpenFacets(open => !open)}
							search={search}
							url={facet.url}
						/>
					);
				}
				return (
					<SelectFacet
						config={facetConfig}
						from={from}
						key={facet.id}
						onChange={() => setOpenFacets(open => !open)}
						search={search}
						url={facet.id}
						values={parsedFacet.values}
					/>
				);
			}),
		[facets, facetsConfig, from, setOpenFacets],
	);

	return ReactDOM.createPortal(
		<RemoveScroll enabled={open}>
			<nav
				className={`${styles.wrapper} ${open ? styles.open : ''}`}
				onClick={event => {
					event.stopPropagation();
					event.preventDefault();
					if (event.target === event.currentTarget) {
						setOpenFacets(false);
					}
				}}
			>
				<div className={`${styles.container} ${className ?? ''}`}>
					<h2>Sort by</h2>
					<select
						aria-label="Sort by"
						onChange={event => {
							navigate({
								from,
								search: previous => ({
									...previous,
									sort:
										event.target.value === defaultOption?.value ? undefined : event.target.value,
								}),
								to: from,
							});
						}}
						value={sort ?? defaultOption?.value}
					>
						{features.search.sortOptions.map(option => (
							<option key={option.value} selected={option.default} value={option.value}>
								{option.title}
							</option>
						))}
					</select>
					<div className={styles.facetsTopContainer}>
						<h2>Filter by</h2>
						<Button asChild size="sm">
							<Link from={from} params={{}} search={(search: any) => ({ q: search.q })} to={from}>
								Clear All
							</Link>
						</Button>
						<button
							aria-label="Close"
							className={styles.closeButton}
							onClick={() => setOpenFacets(false)}
						>
							<IconX />
						</button>
					</div>
					{facetsElements}
				</div>
			</nav>
		</RemoveScroll>,
		document.querySelector('#root-modal')!,
	);
};

export default Facets;
