import { createFileRoute } from '@tanstack/react-router';

import CartItem from '@/components/CartItem/CartItem';
import Summary from '@/components/Summary/Summary';
import { useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './Cart.module.css';

const Cart = () => {
	const lines = useOrderQuery(order => order?.lines) ?? [];
	return (
		<main className={`${styles.cartWrapper} containerGrid`}>
			<div className={styles.linesContainer}>
				{lines.map(line => (
					<CartItem key={line.internalid} line={line} />
				))}
			</div>
			<div className={styles.summaryContainer}>
				<Summary />
			</div>
		</main>
	);
};

export const Route = createFileRoute('/cart/_cart/')({
	component: Cart,
});
