import { IconSearch } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React, { useState } from 'react';

import Button from '@/components/Button/Button';
import Conditional from '@/components/Conditional/Conditional';
import { Dialog, DialogContent } from '@/components/Dialog/Dialog';
import ImageShower from '@/components/ImageShower/ImageShower';
import QuickView from '@/components/QuickView/QuickView';
import { Item } from '@/customTypes/Item';
import { useCacheStore } from '@/stores';

import styles from './ItemComponent.module.css';

interface ItemComponentProperties {
	className?: string;
	item?: Item;
}

const ItemComponent: React.FC<ItemComponentProperties> = ({ className, item }) => {
	const [mouseInside, setMouseInside] = useState(false);
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const [open, setOpen] = useState(false);

	return (
		<Link
			className={`${styles.container} ${className ?? ''}`}
			disabled={!item}
			draggable={false}
			onClick={() => {
				setSimplifiedItem(item!);
			}}
			onDragStart={event => event.preventDefault()}
			onMouseEnter={() => setMouseInside(true)}
			onMouseLeave={() => setMouseInside(false)}
			params={{ itemURL: item?.url ?? '' }}
			search={{}}
			to="/item/$itemURL"
		>
			<ImageShower className={styles.image} item={item} mouseInside={mouseInside} />
			<span className={styles.name}>{item?.name ?? 'Loading...'}</span>
			<span className={styles.category}>Essentials</span>
			<label className={styles.price}>{item ? item.priceFormated : '$__.__'}</label>
			<Conditional visible={item}>
				<Button
					className={styles.quickView}
					onClick={event => {
						event.stopPropagation();
						event.preventDefault();
						setOpen(true);
					}}
				>
					<IconSearch />
				</Button>
				<Dialog onOpenChange={setOpen} open={open}>
					<DialogContent>
						<QuickView item={item!} onClose={() => setOpen(false)} />
					</DialogContent>
				</Dialog>
			</Conditional>
		</Link>
	);
};

export default ItemComponent;
