import { IconAdjustments } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import Facets from '@/components/Facets/Facets';
import PaginatedSearch from '@/components/PaginatedSearch/PaginatedSearch';
import { RangeFacetType, SelectFacetType } from '@/customTypes/Item';
import { queryClient, useCacheStore } from '@/stores';
import { getItems, getSearchKey } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

import styles from './Search.module.css';

export interface SearchParameters {
	[key: string]: number | string | undefined;

	p?: number;
	q: string;
	sort?: string;
}

const getItemsInfo = async (
	search: SearchParameters,
	pageSize: number,
	facets: (RangeFacetType | SelectFacetType)[],
	searchKey: string,
	signal?: AbortSignal,
) =>
	getItems(
		{
			...search,
			fieldset: 'search',
			include: facets ? undefined : 'facets',
			limit: pageSize,
			offset: ((search.p ?? 1) - 1) * pageSize,
		},
		signal,
	).then(response => {
		if (!facets) {
			useCacheStore.getState().setSearchCache(searchKey, response.total, response.facets!);
		}
		return {
			...response,
			facets: facets ?? response.facets,
		};
	});

const Search: React.FC = () => {
	const [openFacets, setOpenFacets] = useState(false);
	const search = Route.useSearch();
	const searchKey = getSearchKey(search);
	const { facets, total } = useCacheStore(store => store.searchCache[searchKey]) ?? {};
	const { data } = useQuery({
		queryFn: ({ signal }) =>
			getItemsInfo(search, features.search.productsPerPage, facets, searchKey, signal),
		queryKey: ['items-search', search],
	});

	// This is needed for local storage cache to work
	useEffect(() => {
		if (data?.facets && !facets) {
			useCacheStore.getState().setSearchCache(searchKey, data.total, data.facets!);
		}
	}, [data, facets, searchKey]);

	return (
		<main className="containerGrid" style={{ overflow: 'hidden', position: 'relative' }}>
			<Breadcrumb
				links={[
					{
						children: 'Home',
						to: '/',
					},
					{
						children: 'Search',
						search: {
							...search,
							p: undefined,
						},
						to: '/search',
					},
				]}
			/>
			<h1>Searching for "{search.q}"</h1>
			<PaginatedSearch
				from="/search"
				items={data?.items}
				page={search.p ?? 1}
				pageSize={features.search.productsPerPage}
				total={total}
			/>
			<button className={styles.openFacetsButton} onClick={() => setOpenFacets(open => !open)}>
				<IconAdjustments />
				Filter & Sort
			</button>
			<Helmet>
				<title>Searching for "{search.q}"</title>
			</Helmet>
			<Facets
				facets={facets}
				from="/search"
				open={openFacets}
				setOpenFacets={setOpenFacets}
				sort={search.sort}
			/>
		</main>
	);
};

const validateSearch = (search: Record<string, any>) => {
	const page = Number.parseInt(search.p, 10);
	const parsedSearch: Record<string, string | undefined> = {};
	for (const key in search) {
		parsedSearch[key] = search[key] === '' ? undefined : search[key];
	}

	const result: SearchParameters = {
		...parsedSearch,
		p: Number.isNaN(page) || page <= 1 ? undefined : page,
		q: search.q?.trim() ?? '',
		sort:
			search.sort === features.search.sortOptions.find(option => option.default)?.value
				? undefined
				: search.sort,
	};
	return result;
};

export const Route = createFileRoute('/search/_search/')({
	component: Search,
	loader: ({ location: { search } }) => {
		const parsedSearch = validateSearch(search);
		const searchKey = getSearchKey(parsedSearch);
		const { facets } = useCacheStore.getState().searchCache[searchKey] ?? {};
		queryClient.prefetchQuery({
			queryFn: ({ signal }) =>
				getItemsInfo(parsedSearch, features.search.productsPerPage, facets, searchKey, signal),
			queryKey: ['items-search', search],
		});
	},
	validateSearch,
});
