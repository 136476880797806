import { Link } from '@tanstack/react-router';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

import Button from '@/components/Button/Button';
import CartItem from '@/components/CartItem/CartItem';
import { Summary } from '@/customTypes/Order';
import { useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './MiniCart.module.css';

const MiniCart: React.FC<{
	isOpen: boolean;
	onClose: () => void;
}> = ({ isOpen, onClose }) => {
	const lines = useOrderQuery(order => order?.lines) ?? [];
	const summary = useOrderQuery(order => order?.summary) ?? ({} as Summary);
	const reference = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const listener = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			if (
				!reference.current?.contains(target) &&
				!document.querySelector('#cart-button')?.contains(target)
			) {
				onClose();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, [onClose]);

	return ReactDOM.createPortal(
		<RemoveScroll enabled={isOpen}>
			<div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
				<menu className={styles.container} ref={reference} tabIndex={-1}>
					<div className={styles.topContainer}>
						<label className={styles.totalItems}>Total Items: {summary.itemcount}</label>
						<Button asChild disabled={lines.length === 0} size="sm" variant="outline">
							<Link
								onClick={() => {
									if (lines.length > 0) onClose();
								}}
								params={{}}
								search={{}}
								to="/cart"
							>
								Edit Cart
							</Link>
						</Button>
					</div>
					<div className={styles.itemsContainer}>
						{summary.itemcount === 0 ? (
							<span>No items in the cart</span>
						) : (
							<div>
								{lines.map(line => (
									<CartItem isMiniCart={true} key={line.internalid} line={line} />
								))}
							</div>
						)}
					</div>

					<div className={styles.subtotalWrapper}>
						<span className={styles.subtotalText}>Subtotal: {summary.total_formatted}</span>
						<Button asChild disabled={lines.length === 0} variant="secondary">
							<Link
								onClick={() => {
									if (lines.length > 0) onClose();
								}}
								params={{}}
								search={{}}
								to="/checkout/opc"
							>
								Continue to Checkout
							</Link>
						</Button>
					</div>
				</menu>
			</div>
		</RemoveScroll>,
		document.querySelector('#root-modal')!,
	);
};

export default MiniCart;
