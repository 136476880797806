import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { IconChevronDown } from '@tabler/icons-react';
import * as React from 'react';

import styles from './Accordion.module.css';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...properties }, reference) => (
	<AccordionPrimitive.Item
		className={className ? `${styles.accordionItem} ${className}` : styles.accordionItem}
		ref={reference}
		{...properties}
	/>
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...properties }, reference) => (
	<AccordionPrimitive.Header className={styles.accordionHeader}>
		<AccordionPrimitive.Trigger
			className={className ? `${styles.accordionTrigger} ${className}` : styles.accordionTrigger}
			ref={reference}
			{...properties}
		>
			{children}
			<IconChevronDown className={styles.chevronIcon} />
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...properties }, reference) => (
	<AccordionPrimitive.Content
		className={className ? `${styles.accordionContent} ${className}` : styles.accordionContent}
		ref={reference}
		role="navigation"
		{...properties}
	>
		{children}
	</AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
