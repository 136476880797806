import { IconHeart } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/Accordion/Accordion';
import AddToCartButton from '@/components/AddToCartButton/AddToCartButton';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import Button from '@/components/Button/Button';
import ImageGallery from '@/components/ImageGallery/ImageGallery';
import ItemOptions from '@/components/ItemOptions/ItemOptions';
import LastViewed from '@/components/LastViewed/LastViewed';
import NumberInput from '@/components/NumberInput/NumberInput';
import RelatedItems from '@/components/RelatedItems/RelatedItems';
import Reviews from '@/components/Reviews/Reviews';
import Skeleton from '@/components/Skeleton/Skeleton';
import Stars from '@/components/Stars/Stars';
import { Item } from '@/customTypes/Item';
import { ItemOption as ItemOptionType } from '@/customTypes/itemResponse';
import { queryClient, useCacheStore, useStateStore } from '@/stores';
import { getItems } from '@/utils/Item';

import styles from './$itemURL.module.css';

/**
 * Fetches item information based on the provided item URL.
 * @param itemURL - The URL of the item to fetch.
 * @returns A promise that resolves to the item information.
 */
export const getItemInfo = async (itemURL: string): Promise<Item> => {
	const search = await getItems({
		fieldset: 'details',
		limit: 1,
		offset: 0,
		url: itemURL,
	});

	if (search.items.length > 0) {
		return search.items[0];
	}
	throw new Error('No results found');
};

const renderHtmlToText = (htmlString: string) => {
	const temporaryDiv = document.createElement('div');
	temporaryDiv.innerHTML = htmlString;
	return temporaryDiv.textContent;
};

const ProductDetailScreen: React.FC = () => {
	const navigate = useNavigate();
	const addToLastViewed = useStateStore(store => store.addToLastViewed);
	const { itemURL } = Route.useParams();
	const search = Route.useSearch();
	const [quantity, setQuantity] = useState(1);
	const [onWishlist, setOnWishlist] = useState(false);
	const simplifiedItem = useCacheStore(store => store.pdpSimplifiedItem);
	const { data, error } = useQuery({
		notifyOnChangeProps: ['data'],
		queryFn: () => getItemInfo(itemURL),
		queryKey: ['item', itemURL],
		staleTime: 1000 * 60 * 10,
	});
	const optionsByKey = useMemo(() => {
		const result: Record<string, ItemOptionType> = {};
		for (const option of data?.options ?? []) {
			result[option.internalid.replace('custcol_', '')] = option;
		}
		return result;
	}, [data?.options]);
	const optionValues = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of Object.keys(search)) {
			result[option] = optionsByKey[option]?.values?.find(
				value => value.internalid === String(search[option]),
			)?.label;
		}
		return result;
	}, [search, optionsByKey]);
	const currentChild = data?.childItems.find(child =>
		Object.keys(optionValues).every(key => child.customFields[key] === optionValues[key]),
	);
	const descriptionString = useMemo(
		() => renderHtmlToText(currentChild?.description ?? data?.description ?? ''),
		[data?.description, currentChild?.description],
	);

	useEffect(() => {
		if (data) {
			addToLastViewed(data!);
		}
	}, [addToLastViewed, data]);

	if (error) {
		return <span>Invalid product ({error.message})</span>;
	}

	const InfoSection = (
		<>
			<h1 className={styles.productTitle}>
				{currentChild?.name ?? data?.name ?? simplifiedItem?.name ?? <Skeleton width="30ch" />}
			</h1>
			<label className={styles.sku}>
				<span className={styles.skuLabel}>SKU: </span>
				{currentChild?.itemId ?? data?.itemId ?? simplifiedItem?.itemId ?? (
					<Skeleton width="20ch" />
				)}
			</label>
			<div className={styles.reviewsContainer}>
				<Stars value={3} />
				<span>2 Reviews</span>
			</div>
		</>
	);

	return (
		<main className="containerGrid">
			<Helmet>
				<title>{data?.name ?? simplifiedItem?.name ?? `Loading: ${itemURL}`}</title>
				<meta content={descriptionString ?? 'Loading...'} name="description" />
			</Helmet>
			<div className={styles.topContainer}>
				<div className={styles.imageContainer}>
					<Breakpoint maxWidth={599}>{InfoSection}</Breakpoint>
					<ImageGallery item={data ?? simplifiedItem} options={optionValues} />
				</div>

				<div className={styles.detailSection}>
					<Breakpoint minWidthExclude={599}>{InfoSection}</Breakpoint>

					<span className={styles.productPrice}>
						{currentChild?.priceFormated ??
							data?.priceFormated ??
							simplifiedItem?.priceFormated ??
							'$__.__'}
					</span>

					<div className={styles.quantityContainer}>
						<span className={styles.quantityText}>Quantity:</span>
						<NumberInput max={999_999} min={1} setValue={setQuantity} value={quantity} />
					</div>
					<ItemOptions
						childItems={data?.childItems ?? []}
						onChange={(key, value) => {
							navigate({
								from: '/item/$itemURL',
								search: previous => ({
									...previous,
									[key.replace('custcol_', '')]: value,
								}),
								to: '/item/$itemURL',
							});
						}}
						options={data?.options ?? []}
						optionsByKey={optionsByKey}
						search={search}
					/>
					<div className={styles.buttonWrapper}>
						<AddToCartButton item={data} quantity={1} />
						<Button
							className={`${styles.wishlistButton} ${onWishlist ? styles.active : ''}`}
							onClick={() => setOnWishlist(!onWishlist)}
							variant="outline"
						>
							<IconHeart />
							<label>{onWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'}</label>
						</Button>
					</div>

					<Accordion collapsible={true} defaultValue="description" type="single">
						<AccordionItem value="description">
							<AccordionTrigger>Description</AccordionTrigger>
							<AccordionContent>
								{data?.description ? (
									// TODO no god! plz no!! nooooooooooooooooooo
									// eslint-disable-next-line react/no-danger
									<div dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
								) : (
									<Skeleton lines={3} />
								)}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				</div>
			</div>
			<span className={styles.freeShipping}>Free Shipping on orders over $50</span>
			<div className={styles.reviewsCounter}>
				<Reviews />
			</div>
			<div className={styles.lastViewedWrapper}>
				<h3>Last Viewed</h3>
				<LastViewed />
			</div>
			<RelatedItems internalId={data?.internalId ?? simplifiedItem?.internalId} />
		</main>
	);
};

export const Route = createFileRoute('/item/$itemURL')({
	component: ProductDetailScreen,
	loader: async ({ params }) => {
		// We dont return this since we want to load the page even if this didnt finish loading
		// Because of simplifiedItem
		setTimeout(() => {
			queryClient.prefetchQuery({
				queryFn: () => getItemInfo(params.itemURL),
				queryKey: ['item', params.itemURL],
				staleTime: 1000 * 60,
			});
		}, 0);
	},
	validateSearch: (search: Record<string, any>) => search,
});
