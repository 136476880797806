import {
	IconLoader2,
	IconLogin2,
	IconShoppingBag,
	IconUserCircle,
	IconX,
} from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';

import LogoColor from '@/assets/logo-color.svg';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import Button from '@/components/Button/Button';
import Conditional from '@/components/Conditional/Conditional';
import Menu from '@/components/Menu/Menu';
import MenuBar from '@/components/MenuBar/MenuBar';
import MenuToggle from '@/components/MenuToggle/MenuToggle';
import MiniCart from '@/components/MiniCart/MiniCart';
import SearchBar from '@/components/SearchBar/SearchBar';
import { useOrderLoading, useOrderQuery } from '@/stores/OrderHooks.ts';
import { useLogOutMutation, useProfileLoading, useProfileQuery } from '@/stores/ProfileHooks.ts';

import styles from './Header.module.css';

const Header = () => {
	const lineCount = useOrderQuery(order => order?.lines.length) ?? 0;
	const loadingCart = useOrderLoading();
	const isLoggedIn = useProfileQuery(profile => profile?.isLoggedIn === 'T');
	const loadingProfile = useProfileLoading();
	const [cartOpen, setCartOpen] = useState(false);
	const logOut = useLogOutMutation();

	return (
		<header className={styles.header}>
			<div className="containerGrid">
				<div className={styles.containerWrapper}>
					<MenuToggle className={styles.menuToggle} id="menu-button" />
					<Link className={styles.logo} params={{}} search={{}} to="/">
						<img alt="logo" src={LogoColor} />
					</Link>
					<Breakpoint minWidth={992}>
						<SearchBar className={styles.searchBarDesktop} />
					</Breakpoint>
					<div style={{ alignItems: 'center', display: 'flex' }}>
						<Conditional visible={isLoggedIn}>
							<Button
								aria-label="Logout"
								className={styles.profile}
								disabled={loadingProfile}
								onClick={event => {
									if (isLoggedIn && !loadingProfile) {
										event.preventDefault();
										logOut.mutate();
									}
								}}
								size="icon"
							>
								<IconLogin2 />
							</Button>
							<Link
								aria-label="My Account"
								className={styles.profile}
								disabled={loadingProfile}
								params={{}}
								search={{}}
								to="/account"
							>
								{loadingProfile ? <IconLoader2 className="loader" /> : <IconUserCircle />}
							</Link>
						</Conditional>
						<Conditional visible={!isLoggedIn}>
							<Link
								aria-label="Login"
								className={styles.profile}
								disabled={loadingProfile}
								onClick={event => {
									if (isLoggedIn && !loadingProfile) {
										event.preventDefault();
										logOut.mutate();
									}
								}}
								params={{}}
								search={{}}
								to="/login"
							>
								{loadingProfile ? <IconLoader2 className="loader" /> : <IconUserCircle />}
							</Link>
						</Conditional>
						<button
							aria-label="Cart"
							className={styles.cartButton}
							id="cart-button"
							onClick={() => {
								setCartOpen(wasOpen => !wasOpen);
							}}
						>
							{cartOpen ? <IconX /> : <IconShoppingBag />}
							{loadingCart && !lineCount ? (
								<IconLoader2 className="loader" />
							) : (
								<label>{lineCount}</label>
							)}
						</button>
					</div>
				</div>
			</div>
			<Breakpoint maxWidthExclude={992}>
				<div className={styles.searchBarMobile}>
					<SearchBar />
				</div>
			</Breakpoint>
			<Breakpoint minWidth={992}>
				<MenuBar />
			</Breakpoint>
			<MiniCart isOpen={cartOpen} onClose={() => setCartOpen(false)} />
			<Breakpoint maxWidthExclude={992}>
				<Menu />
			</Breakpoint>
		</header>
	);
};

export default Header;
