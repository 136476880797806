import React, { useMemo } from 'react';

import MenuElement from '@/components/MenuElement/MenuElement';
import { useConfigStore } from '@/stores';

import styles from './MenuBar.module.css';

export const MenuBar: React.FC = () => {
	const categories = useConfigStore(store => store.categories);
	const menu = useMemo(
		() => categories.map(item => <MenuElement item={item} key={item.internalid} />),
		[categories],
	);

	return (
		<div className={styles.wrapper}>
			<ul className={`${styles.container} containerGrid`}>{menu}</ul>
		</div>
	);
};

export default MenuBar;
