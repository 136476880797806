import React from 'react';

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/Carousel/Carousel';
import ItemComponent from '@/components/ItemComponent/ItemComponent';
import { Item } from '@/customTypes/Item';

import styles from './ItemCarousel.module.css';

interface ItemCarouselProperties {
	className?: string;
	items?: Item[];
	loadingAmount?: number;
}

const ItemCarousel: React.FC<ItemCarouselProperties> = ({ items, loadingAmount }) => (
	<Carousel opts={{ dragFree: true, dragThreshold: 10, loop: true }}>
		<CarouselContent>
			{(items ?? Array.from({ length: loadingAmount ?? 0 })).map((item, index) => (
				<CarouselItem className={styles.itemContainer} key={index}>
					<ItemComponent item={item} key={index} />
				</CarouselItem>
			))}
		</CarouselContent>
		<CarouselPrevious />
		<CarouselNext />
	</Carousel>
);

export default ItemCarousel;
