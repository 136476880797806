import { useQuery } from '@tanstack/react-query';
import React from 'react';

import Conditional from '@/components/Conditional/Conditional';
import ItemCarousel from '@/components/ItemCarousel/ItemCarousel';
import { getItems } from '@/utils/Item.ts';

/**
 * Fetches related items based on the provided id.
 * @param id - The identifier for the item search.
 * @returns A promise that resolves to an array of related items.
 */
const getRelatedItems = async (id: number) => {
	const search = await getItems({
		fieldset: 'correlateditems_details',
		id,
		limit: 10,
		offset: 0,
	});

	if (search.items.length > 0 && search.items[0].correlatedItemsDetail) {
		return search.items[0].correlatedItemsDetail;
	}
	throw new Error('No results found');
};

export const RelatedItems: React.FC<{ internalId?: number }> = ({ internalId }) => {
	const { data } = useQuery({
		enabled: Boolean(internalId),
		queryFn: () => getRelatedItems(internalId!),
		queryKey: ['related-item', internalId],
	});
	return (
		<Conditional visible={data?.length}>
			<h3>Related Items</h3>
			<ItemCarousel items={data ?? []} loadingAmount={10} />
		</Conditional>
	);
};

export default RelatedItems;
