import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CatchBoundary, createRootRoute, Outlet, ScrollRestoration } from '@tanstack/react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import NotFount from '@/components/NotFound/NotFount.tsx';
import QueryProvider from '@/stores/QueryProvider.tsx';
import features from '@/vendor/features.ts';

export const Route = createRootRoute({
	component: () => (
		<CatchBoundary getResetKey={() => 'root'}>
			<QueryProvider>
				<HelmetProvider>
					<div style={{ minHeight: '100vh', width: '100%' }}>
						<Header />
						<CatchBoundary getResetKey={() => 'error'}>
							<Outlet />
						</CatchBoundary>
					</div>
					<Footer />
					<Helmet>
						<title>{features.vendorName}</title>
						<link
							href={
								process.env.NODE_ENV === 'production'
									? `/awa-commerce/${features.network.uploadURL}/assets/favicon.png`
									: '/assets/favicon.png'
							}
							rel="icon"
							type="image/svg+xml"
						/>
					</Helmet>
				</HelmetProvider>
				<ReactQueryDevtools buttonPosition="bottom-right" />
				<ScrollRestoration />
			</QueryProvider>
		</CatchBoundary>
	),
	notFoundComponent: NotFount,
});
