import { Item, SimplifiedItem } from '@/customTypes/Item';
import { createStore, setter } from '@/utils/store';

interface State {
	lastViewed: SimplifiedItem[];
	menuOpen: boolean;
	pageWidth: number;
	quickViewItem: Item | SimplifiedItem | undefined;
	searchCache: Record<string, { cache: Record<number, Item[]>; total: number }>;
	typeaheadOpen: boolean;
}

// Global state, meant to be used for interaction between components
// TODO move searchcache to cache store
const state: State = {
	lastViewed: JSON.parse(localStorage.getItem('last-viewed') ?? '[]') as SimplifiedItem[],
	menuOpen: false,
	pageWidth: window.innerWidth,
	quickViewItem: undefined,
	searchCache: {},
	typeaheadOpen: false,
};

export interface StateStore {
	addToLastViewed: (item: SimplifiedItem) => void;
	setState: (profile: Partial<typeof state>) => void;
	state: typeof state;
}

export default createStore<StateStore>((set, get) => {
	const setState: StateStore['setState'] = data => {
		set({
			state: setter(data, get().state),
		});
	};
	window.addEventListener(
		'resize',
		() => {
			setState({
				pageWidth: Math.floor(window.innerWidth),
			});
		},
		{ passive: true },
	);
	return {
		addToLastViewed: item => {
			const items = (JSON.parse(localStorage.getItem('last-viewed') ?? '[]') as SimplifiedItem[])
				.filter(currentItem => currentItem.url !== item.url)
				.slice(0, 5);
			items.unshift({
				customFields: item.customFields,
				imagesDetail: item.imagesDetail,
				internalId: item.internalId,
				itemId: item.itemId,
				name: item.name,
				priceFormated: item.priceFormated,
				url: item.url,
			});
			localStorage.setItem('last-viewed', JSON.stringify(items));
			setState({ lastViewed: items });
		},
		setState,
		state,
	};
});
