import { Link } from '@tanstack/react-router';
import React from 'react';

import Switch from '@/components/Switch/Switch';
import { FacetItem } from '@/customTypes/environment';

import styles from './BooleanFacet.module.css';

interface BooleanFacetProperties {
	className?: string;
	config?: FacetItem;
	from: '/category/$' | '/search';
	onChange?: () => void;
	search: Record<string, string | undefined>;
	url: string;
}

const BooleanFacet: React.FC<BooleanFacetProperties> = ({
	className,
	config,
	from,
	onChange,
	search,
	url,
}) => {
	const selectedValue = !!search[url];

	return (
		<Link
			className={`${styles.option} ${className ?? ''}`}
			from={from}
			onClick={onChange}
			params={{}}
			search={search => ({
				...search,
				p: undefined,
				[url]: selectedValue ? undefined : 'true',
			})}
			to={from}
		>
			<span>{config?.name ?? url}</span>
			<Switch checked={selectedValue} />
		</Link>
	);
};

export default BooleanFacet;
