import React from 'react';

import ItemComponent from '@/components/ItemComponent/ItemComponent';
import Pagination from '@/components/Pagination/Pagination';
import { Item } from '@/customTypes/Item';

import styles from './PaginatedSearch.module.css';

export const PaginatedSearch: React.FC<{
	from: '/category/$' | '/search';
	items?: Item[];
	page: number;
	pageSize: number;
	total?: number;
}> = ({ from, items, page, pageSize, total }) => {
	const parsedItems =
		items ??
		Array.from({ length: total ? Math.min(pageSize, total - (page - 1) * pageSize) : pageSize });
	const totalPages = Math.ceil((total ?? 0) / pageSize);
	return (
		<div>
			<div className={styles.container}>
				{parsedItems.map((item, index) => (
					<ItemComponent item={item} key={index} />
				))}
			</div>
			<Pagination from={from} page={page} pages={totalPages} />
		</div>
	);
};

export default PaginatedSearch;
