import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DependencyList, useCallback } from 'react';

import Profile from '@/customTypes/Profile';
import { orderQueryOptions } from '@/stores/OrderHooks.ts';
import { customFetch } from '@/utils/network.ts';

export const profileQueryOptions: UseQueryOptions<Profile, unknown, Profile> = {
	queryFn: async () => ({
		...(await customFetch<Profile>({
			headers: {
				'X-SC-Touchpoint': 'shopping',
			},
			url: '/services/Profile.Service.ss',
		})),
		loading: false,
	}),
	queryKey: ['profile'],
	staleTime: 1000 * 60,
};

// Fetches a slice of the current profile and re-renders when the slice changes
// If your selector function depends on other variables, you can pass them as a second argument
export const useProfileQuery = <T>(
	selector: (order?: Profile) => T,
	selectorDeps: DependencyList = [],
) => {
	const query = useQuery<Profile, unknown, T>({
		...profileQueryOptions,
		notifyOnChangeProps: ['data'],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		select: useCallback(selector, selectorDeps),
	});
	return query.data as T;
};

export const useProfileLoading = () => {
	const query = useQuery({
		...profileQueryOptions,
		notifyOnChangeProps: ['isLoading'],
	});
	const dataQuery = useQuery<Profile, unknown, boolean>({
		...profileQueryOptions,
		notifyOnChangeProps: ['data'],
		select: useCallback((data: Profile) => data.loading, []),
	});
	return query.isLoading || dataQuery.data;
};

export const useLoginMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<Profile, unknown, { email: string; password: string; token?: string }>({
		mutationFn: async ({ email, password, token }) => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: true,
			});
			return customFetch<{ user: Profile }>({
				body: {
					email,
					'grecaptcha-token': token,
					password,
					redirect: 'true',
				},
				method: 'POST',
				url: '/services/Account.Login.Service.ss',
			}).then(response => response.user);
		},
		onSuccess: data => {
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, {
				...data,
				loading: false,
			});
			queryClient.invalidateQueries(orderQueryOptions);
		},
	});
};

export const useLogOutMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: () => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: true,
			});
			return customFetch({
				text: true,
				url: '/logOut.ssp',
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(profileQueryOptions);
			queryClient.invalidateQueries(orderQueryOptions);
		},
	});
};
