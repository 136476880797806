import { useSearch } from '@tanstack/react-router';
import React from 'react';

import Button from '@/components/Button/Button';
import { Item, SimplifiedItem } from '@/customTypes/Item';
import { useAddLinesMutation, useOrderLoading } from '@/stores/OrderHooks.ts';

interface AddToCartButtonProperties {
	item?: Item;
	quantity: number;
}

const getItemToAdd = (
	item: Item,
	options: Record<string, string>,
): Item | SimplifiedItem | undefined => {
	if (!item.childItems) {
		return item;
	}
	const optionAsLabels = Object.entries(options).map(([key, value]) => ({
		key,
		label: item.options
			?.find(option => option.internalid.replace('custcol_', '') === key)
			?.values?.find(optionValue => optionValue.internalid === String(value))?.label,
	}));

	return item.childItems.find(childItem =>
		optionAsLabels.every(option => childItem.customFields[option.key] === option.label),
	);
};

const AddToCartButton: React.FC<AddToCartButtonProperties> = ({ item, quantity }) => {
	const search = useSearch({ strict: false });
	const addToCart = useAddLinesMutation();
	const loadingCart = useOrderLoading();

	const handleAddToCart = () => {
		const toAdd = getItemToAdd(item!, search);
		if (!toAdd) {
			throw new Error('No item to add');
		}

		addToCart.mutate([
			{
				item: {
					internalid: toAdd.internalId,
					itemtype: 'InvtPart',
				},
				quantity,
			},
		]);
	};

	return (
		<Button disabled={!item || loadingCart} onClick={handleAddToCart}>
			Add to Cart
		</Button>
	);
};

export default AddToCartButton;
