import { Link } from '@tanstack/react-router';
import React from 'react';

import { SimplifiedItem } from '@/customTypes/Item';
import { ItemOption as ItemOptionType } from '@/customTypes/itemResponse';
import features from '@/vendor/features.ts';

import styles from './ItemOptions.module.css';

const isAvailable = (
	items: SimplifiedItem[],
	values: Record<string, any>,
	optionsByKey: Record<string, ItemOptionType>,
) =>
	items.some(item =>
		Object.keys(values).every(key => {
			const valueLabel = optionsByKey[key]?.values?.find(
				value => value.internalid === values[key].toString(),
			)?.label;
			return (
				item.customFields[optionsByKey[key]?.sourcefrom?.replace('custitem_', '') ?? ''] ===
				valueLabel
			);
		}),
	);

export const ItemOptions: React.FC<{
	childItems?: SimplifiedItem[];
	onChange: (key: string, value: string) => void;
	options: ItemOptionType[];
	optionsByKey: Record<string, ItemOptionType>;
	search: Record<string, string | undefined>;
}> = ({ childItems, onChange, options, optionsByKey, search }) => (
	<div className={styles.container}>
		{options
			.filter(option => !features.pdp.hiddenFacets.includes(option.internalid))
			.map(option => (
				<div className={styles.option} key={option.internalid}>
					<label htmlFor={option.internalid}>{option.label}</label>
					<div className={styles.optionWrapper}>
						{option.type === 'select' ? (
							option.values?.slice(1).map(value => {
								const available = isAvailable(
									childItems ?? [],
									{
										...search,
										[option.internalid.replace('custcol_', '')]: value.internalid,
									},
									optionsByKey,
								);
								return (
									<Link
										className={`${styles.facetLink} ${available ? '' : styles.notAvailable}`}
										from="/item/$itemURL"
										key={value.internalid}
										params={{}}
										replace
										search={{
											...(available ? search : {}),
											[option.internalid.replace('custcol_', '')]: Number(value.internalid),
										}}
										to="/item/$itemURL"
									>
										{value.label}
									</Link>
								);
							})
						) : (
							<input
								id={option.internalid}
								onChange={event => onChange(option.internalid, event.currentTarget.value)}
								type={option.type}
								value={search[option.internalid.replace('custcol_', '')] ?? ''}
							/>
						)}
					</div>
				</div>
			))}
	</div>
);

export default ItemOptions;
