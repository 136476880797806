import { IconAdjustments } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link, LinkProps } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import Facets from '@/components/Facets/Facets';
import PaginatedSearch from '@/components/PaginatedSearch/PaginatedSearch';
import { queryClient, useCacheStore, useConfigStore } from '@/stores';
import { fetchCategories, validateSearch } from '@/utils/category.ts';
import { getItems, getSearchKey } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

import styles from './Category.module.css';

const getItemsInfo = async (
	rest: Record<string, any>,
	pageSize: number,
	getFacets: boolean,
	searchKey: string,
	categoryURL: string,
	signal?: AbortSignal,
) =>
	getItems(
		{
			commercecategoryurl: '/' + categoryURL,
			...rest,
			fieldset: 'search',
			include: getFacets ? 'facets' : undefined,
			limit: pageSize,
			offset: ((rest.p ?? 1) - 1) * pageSize,
		},
		signal,
	).then(response => {
		if (getFacets) {
			useCacheStore.getState().setSearchCache(searchKey, response.total, response.facets!);
		}
		return response.items;
	});

const CategoryPage = () => {
	const [openFacets, setOpenFacets] = useState(false);
	const { _splat: categoryURL } = Route.useParams();
	const categories = useConfigStore(store => store.categories);
	const categoryInfo = useMemo(
		() => categories.find(item => item.fullurl === `/${categoryURL}`),
		[categories, categoryURL],
	);
	// TODO move this to a child component that has the infinite or regular scroll and the facets
	// That way it doesn't re-render the whole page
	const search = Route.useSearch();
	const searchKey = useMemo(
		() =>
			getSearchKey({
				categoryURL,
				...search,
			}),
		[categoryURL, search],
	);
	const { data } = useQuery({
		enabled: Boolean(categoryURL),
		queryFn: () => fetchCategories(categoryURL!),
		queryKey: ['category-categories', categoryURL],
	});
	const { facets, total } = useCacheStore(store => store.searchCache[searchKey]) ?? {};

	const { data: items } = useQuery({
		enabled: Boolean(categoryURL),
		queryFn: ({ signal }) =>
			getItemsInfo(
				search,
				features.search.productsPerPage,
				!facets,
				searchKey,
				categoryURL!,
				signal,
			),
		queryKey: ['category-items', search, categoryURL],
	});

	const requestInfo = data?.[0];

	const links: LinkProps[] =
		requestInfo?.breadcrumb.map(item => ({
			children: item.name,
			params: { _splat: item.fullurl },
			to: '/category/$',
		})) ?? [];

	return (
		<>
			<main className={`${styles.main} containerGrid`}>
				<Breadcrumb links={[{ children: 'Home', to: '/' }, ...links]} />
				<div className={styles.topContainer}>
					<img
						alt={requestInfo?.pagetitle}
						src={`http://localhost:8081${requestInfo?.pagebannerurl}`}
					/>
					<span>{requestInfo?.pagetitle ?? categoryInfo?.name ?? categoryURL}</span>
				</div>
				<div className={styles.gridContainer}>
					{requestInfo?.categories.map(category => (
						<Link
							className={styles.category}
							key={category.internalid}
							params={{ _splat: category.fullurl }}
							search={{
								p: undefined,
							}}
							to="/category/$"
						>
							<h2>{category.name}</h2>
							<img alt={category.name} src={`http://localhost:8081${category.pagebannerurl}`} />
						</Link>
					))}
				</div>
				<PaginatedSearch
					from="/category/$"
					items={items}
					page={search.p ?? 1}
					pageSize={features.search.productsPerPage}
					total={total}
				/>
				<button className={styles.openFacetsButton} onClick={() => setOpenFacets(open => !open)}>
					<IconAdjustments />
					Filter & Sort
				</button>
			</main>

			<Facets
				facets={facets}
				from="/category/$"
				open={openFacets}
				setOpenFacets={setOpenFacets}
				sort={search.sort as string}
			/>
			<Helmet>
				<title>{requestInfo?.pagetitle ?? categoryInfo?.name ?? categoryURL}</title>
			</Helmet>
		</>
	);
};

export const Route = createFileRoute('/category/_category/$')({
	component: CategoryPage,
	loader: ({ location: { search }, params: { _splat: categoryURL } }) => {
		const parsedSearch = validateSearch(search);
		const searchKey = getSearchKey({
			categoryURL,
			...parsedSearch,
		});
		const cache = useCacheStore.getState().searchCache[searchKey];
		queryClient.prefetchQuery({
			queryFn: () =>
				getItemsInfo(
					parsedSearch,
					features.search.productsPerPage,
					!cache,
					searchKey,
					categoryURL ?? '',
				),
			queryKey: ['category-items', search, categoryURL],
		});
		queryClient.prefetchQuery({
			queryFn: () => fetchCategories(categoryURL!),
			queryKey: ['category-categories', categoryURL],
		});
	},
	validateSearch,
});
