import { IconChevronLeft, IconChevronRight, IconDots } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React from 'react';

import Conditional from '@/components/Conditional/Conditional';

import styles from './Pagination.module.css';

const Pagination: React.FC<{
	className?: string;
	from: '/category/$' | '/search';
	page: number;
	pages: number;
}> = ({ className, from, page, pages }) => {
	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageNumbersToShow = 5; // Adjust as needed

		if (pages <= maxPageNumbersToShow) {
			for (let index = 1; index <= pages; index++) {
				pageNumbers.push(index);
			}
		} else {
			pageNumbers.push(1);

			if (page > 3) {
				pageNumbers.push('ellipsis');
			}

			if (page > 2) {
				pageNumbers.push(page - 1);
			}

			if (page !== 1 && page !== pages) {
				pageNumbers.push(page);
			}

			if (page < pages - 1) {
				pageNumbers.push(page + 1);
			}

			if (page < pages - 2) {
				pageNumbers.push('ellipsis');
			}

			pageNumbers.push(pages);
		}

		return pageNumbers.map((pageNumber, index) => {
			if (pageNumber === 'ellipsis') {
				return (
					<span className={styles.paginationEllipsis} key={index}>
						<IconDots className={styles.icon} />
					</span>
				);
			} else {
				const isActive = pageNumber === page;

				return (
					<li className={styles.paginationItem} key={index}>
						<Link
							aria-current={isActive ? 'page' : undefined}
							className={`${styles.paginationLink} ${isActive ? styles.active : ''}`}
							from={from}
							params={{}}
							search={search => ({
								...search,
								p: pageNumber === 1 ? undefined : (pageNumber as number),
							})}
							to={from}
						>
							{pageNumber}
						</Link>
					</li>
				);
			}
		});
	};

	return (
		<nav
			aria-label="pagination"
			className={`${styles.pagination} ${className ?? ''}`}
			role="navigation"
		>
			<ul className={styles.paginationContent}>
				<Conditional visible={page > 1}>
					<li className={styles.paginationItem}>
						<Link
							className={styles.paginationPrevious}
							from={from}
							params={{}}
							search={search => ({
								...search,
								p: search.p && search.p >= 2 ? search.p - 1 : undefined,
							})}
							to={from}
						>
							<IconChevronLeft className={styles.icon} />
							<span>Previous</span>
						</Link>
					</li>
				</Conditional>
				{renderPageNumbers()}
				<Conditional visible={page < pages}>
					<li className={styles.paginationItem}>
						<Link
							className={styles.paginationNext}
							from={from}
							params={{}}
							search={search => ({
								...search,
								p: (search.p ?? 1) + 1,
							})}
							to={from}
						>
							<span>Next</span>
							<IconChevronRight className={styles.icon} />
						</Link>
					</li>
				</Conditional>
			</ul>
		</nav>
	);
};

export default Pagination;
