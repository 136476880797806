import type { Features } from './features.d';
import type { ItemResponseItem, ItemResponseSimplifiedItem } from '../customTypes/itemResponse';

const features: Features = {
	broadcastChannel: false,
	development: {
		enableMillion: false,
	},
	network: {
		preloadDelay: 300,
		proxyURL: 'latest-sca.awalabs.site',
		ssp: '/sca-dev-2023-2-0',
		uploadURL: 'scact.awalabs.site',
	},
	parseItem: (item: ItemResponseItem) => {
		const result: ReturnType<typeof features.parseItem> = {
			description: item.storedetaileddescription,
			imagesDetail: item.itemimages_detail,
			internalId: item.internalid,
			itemId: item.itemid,
			name: item.displayname,
			options: item.itemoptions_detail?.fields,
			pageTitle: item.pagetitle,
			price: item.onlinecustomerprice_detail?.onlinecustomerprice,
			priceFormated: item.onlinecustomerprice_detail?.onlinecustomerprice_formatted,
			type: item.itemtype,
			url: item.urlcomponent,
		};
		return result;
	},
	parseSimplifiedItem: (item: ItemResponseSimplifiedItem) => ({
		imagesDetail: item.itemimages_detail,
		internalId: item.internalid,
		itemId: item.itemid,
		name: item.displayname,
		priceFormated: item.onlinecustomerprice_detail?.onlinecustomerprice_formatted,
		url: item.urlcomponent,
	}),
	pdp: {
		hiddenFacets: ['custcol_dfp_baseprice', 'custcol_dfp_estshipdate', 'custcol_revision_opt'],
	},
	pwaOptions: undefined,
	search: {
		infiniteScroll: true,
		productsPerPage: 20,
		searchSpring: true,
		sortOptions: [
			{ default: true, title: 'Relevance', value: 'relevance:desc' },
			{ title: 'Price: Low to High', value: 'onlinecustomerprice:asc' },
			{ title: 'Price: High to Low', value: 'onlinecustomerprice:desc' },
			{ title: 'Name: A to Z', value: 'storedisplayname:asc' },
			{ title: 'Name: Z to A', value: 'storedisplayname:desc' },
		],
	},
	services: {
		builderIO: {
			apiKey: 'b5413c2ba9e74a0b84e2a6acfc884243',
		},
		captcha: {
			siteKey: '6LcVhiApAAAAAA_qLZH_-LsqABnobMHuvj6PIAo0',
		},
	},
	vendorName: 'AWA Commerce',
};

export default features;
