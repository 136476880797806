import { Link } from '@tanstack/react-router';
import React from 'react';

import { Category } from '@/customTypes/environment';

import styles from './MenuElement.module.css';

const MenuElement: React.FC<{ item: Category; onClick?: () => void }> = ({ item, onClick }) => {
	if (!item.categories) {
		return (
			<li className={styles.parent}>
				<Link
					className={styles.link}
					onClick={() => {
						onClick?.();
					}}
					params={{
						_splat: item.fullurl.replace('/', ''),
					}}
					search={{
						p: undefined,
					}}
					to="/category/$"
				>
					{item.name}
				</Link>
			</li>
		);
	}

	return (
		<li className={styles.parent}>
			<Link
				className={styles.link}
				onClick={() => {
					onClick?.();
				}}
				params={{
					_splat: item.fullurl.replace('/', ''),
				}}
				search={{
					p: undefined,
				}}
				to="/category/$"
			>
				{item.name}
			</Link>

			<ul className={styles.children}>
				<div className={`${styles.menuWrapper} containerGrid`}>
					<div className={styles.categoryImageContainer}>
						<img
							alt=""
							src="https://www.possupply.com/sca/mapped_images/erc-30-34-38-cartridge-ribbon-12-ribbons-box-3.0.jpg"
						/>
					</div>
					<ul className={styles.categoryLinkContainer}>
						{item.categories.map(child => (
							<li key={child.internalid}>
								<Link
									className={styles.link}
									onClick={() => {
										onClick?.();
									}}
									params={{
										_splat: child.fullurl.replace('/', ''),
									}}
									search={{
										p: undefined,
									}}
									to="/category/$"
								>
									{child.name}
								</Link>
							</li>
						))}
					</ul>
				</div>
			</ul>
		</li>
	);
};

export default MenuElement;
