import React from 'react';

export const Footer: React.FC = () => (
	<footer
		style={{
			alignItems: 'center',
			backgroundColor: 'green',
			display: 'flex',
			height: '100px',
			justifyContent: 'center',
			width: '100%',
		}}
	>
		<span>Salchi footer</span>
	</footer>
);

export default Footer;
