import { IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link, useMatch, useNavigate } from '@tanstack/react-router';
import React, { useEffect } from 'react';

import { getItemInfo } from '@/routes/item/$itemURL.tsx';
import { fetchCategories } from '@/utils/category.ts';

import styles from './NotFound.module.css';

export const NotFount: React.FC = () => {
	useMatch({ strict: false });
	const path = window.location.pathname;
	const navigate = useNavigate();
	const { data, error } = useQuery({
		queryFn: () => getItemInfo(path),
		queryKey: ['item', path.replace(/(?:\/item)?\//, '')],
		retry: false,
	});
	const { data: categoryData, error: categoryError } = useQuery({
		queryFn: () => fetchCategories(path.replace(/^\/*/, '')),
		queryKey: ['category-categories', path.replace(/^\/*/, '')],
		retry: false,
	});

	useEffect(() => {
		if (data) {
			const searchParameters = new URLSearchParams(window.location.search);
			const search = {};

			for (const [key, value] of searchParameters.entries()) {
				// @ts-expect-error
				search[key] = value;
			}
			navigate({
				params: {
					itemURL: data.url,
				},
				search,
				to: '/item/$itemURL',
			});
		}
	}, [data, navigate]);

	useEffect(() => {
		if (categoryData) {
			const searchParameters = new URLSearchParams(window.location.search);
			const search = {};

			for (const [key, value] of searchParameters.entries()) {
				// @ts-expect-error
				search[key] = value;
			}
			navigate({
				params: {
					_splat: path.replace(/^\//, ''),
				},
				search: search as any,
				to: '/category/$',
			});
		}
	}, [categoryData, navigate, path]);

	return (
		<main className={`${styles.container} containerGrid`}>
			{error && categoryError ? (
				<>
					<p>This page was not found :(</p>
					<Link params={{}} search={{}} to="/">
						Go To Home
					</Link>
				</>
			) : (
				<IconLoader2 className={`${styles.loader} loader`} />
			)}
		</main>
	);
};

export default NotFount;
