import { IconClock, IconTrash } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React, { useEffect, useMemo, useState } from 'react';

import ImageShower from '@/components/ImageShower/ImageShower';
import NumberInput from '@/components/NumberInput/NumberInput';
import TooltipButton from '@/components/TooltipButton/TooltipButton';
import { Line } from '@/customTypes/Order';
import { useCacheStore } from '@/stores';
import {
	useOrderLoading,
	useRemoveLineMutation,
	useUpdateLineMutation,
} from '@/stores/OrderHooks.ts';

import styles from './CartItem.module.css';

const CartItem: React.FC<{
	isMiniCart?: boolean;
	line: Line;
}> = ({ isMiniCart, line }) => {
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const [mouseInside, mouseInsideSet] = React.useState(false);
	const loadingOrder = useOrderLoading();
	const updateLine = useUpdateLineMutation();
	const removeLine = useRemoveLineMutation();
	const [inputValue, setInputValue] = useState(line?.quantity ?? 1);

	useEffect(() => {
		if (!loadingOrder) {
			setInputValue(line!.quantity);
		}
	}, [line, loadingOrder]);

	useEffect(() => {
		if (line && !loadingOrder) {
			const timeout = setTimeout(() => {
				if (inputValue === line.quantity) {
					return line;
				}
				updateLine.mutate({ ...line, quantity: inputValue });
			}, 300);

			return () => clearTimeout(timeout);
		}
	}, [inputValue, line, loadingOrder, updateLine]);

	const optionValues = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.cartOptionId.replace('custcol_', '')] = option.value.label;
		}
		return result;
	}, [line?.options]);
	const optionIds = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.cartOptionId.replace('custcol_', '')] = option.value.internalid;
		}
		return result;
	}, [line?.options]);
	const optionValuesByLabel = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.label] = option.value.label;
		}
		return result;
	}, [line?.options]);

	return (
		<Link
			className={`${isMiniCart ? styles.containerMiniCart : styles.container}`}
			disabled={loadingOrder}
			onClick={() => {
				setSimplifiedItem(line.item);
			}}
			onMouseEnter={() => mouseInsideSet(true)}
			onMouseLeave={() => mouseInsideSet(false)}
			params={{ itemURL: (line?.item.url || line?.item.parent?.url) ?? '' }}
			search={optionIds}
			to="/item/$itemURL"
		>
			<ImageShower
				className={styles.image}
				item={line?.item}
				mouseInside={mouseInside}
				options={optionValues}
			/>
			<div className={styles.details}>
				<label>{line?.item.name}</label>
				<label className={styles.price}>{line?.item.priceFormated}</label>
				<div className={styles.options}>
					<label className={styles.qtyLabel}>Quantity: {line?.quantity}</label>
					{Object.keys(optionValuesByLabel).map(option => (
						<label className={styles.qtyLabel} key={option}>
							{option}: {optionValuesByLabel[option]}
						</label>
					))}
				</div>
				<TooltipButton
					className={styles.removeButton}
					disabled={loadingOrder}
					onClick={event => {
						removeLine.mutate(line.internalid);
						event.stopPropagation();
						event.preventDefault();
					}}
					tooltip="Remove from Cart"
				>
					<IconTrash />
				</TooltipButton>
				<div className={styles.qtyWrapperCart}>
					<NumberInput
						disabled={loadingOrder}
						min={1}
						setValue={setInputValue}
						value={inputValue}
					/>

					<TooltipButton className={styles.addToWishlist} tooltip="Add to Wishlist">
						<IconClock />
					</TooltipButton>
				</div>
			</div>
		</Link>
	);
};

export default CartItem;
