import { createRouter, RouterProvider } from '@tanstack/react-router';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import features from '@/vendor/features.ts';

import { routeTree } from './routeTree.gen';

// Import CSS
import './assets/globals.css';
import './vendor/overwrites.css';

if (process.env.NODE_ENV !== 'production') {
	const { default: axe } = await import('@axe-core/react');
	axe(React, ReactDOM, 1000, {});
}

// Create a new router instance
export const router = createRouter({
	defaultPendingMinMs: 0,
	defaultPreload: features.network.preloadDelay === undefined ? false : 'intent',
	// We determine how fast the network is by how long the js took to download and start executing
	defaultPreloadDelay: features.network.preloadDelay,
	// We determine how fast the network is by how long the js took to download and start executing
	defaultPreloadStaleTime: 0,
	routeTree,
	// Remove undefined values from the search
	stringifySearch: search => {
		// TODO fix problem with quotes being added to props
		const result: string[] = [];
		for (const key of Object.keys(search).sort()) {
			if (search[key]) {
				result.push(`${encodeURIComponent(key)}=${encodeURIComponent(search[key])}`);
			}
		}
		return result.length > 0 ? `?${result.join('&')}` : '';
	},
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}
// Render the app
const rootElement = document.querySelector('#root')!;
if (!rootElement.innerHTML) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>,
	);
}
