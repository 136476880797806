import { IconLoader2 } from '@tabler/icons-react';
import React, { useMemo } from 'react';

import {
	Carousel,
	CarouselApi,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/Carousel/Carousel';
import { Item } from '@/customTypes/Item';
import { getImageWithOptions } from '@/utils/Item.ts';

import styles from './ImageGallery.module.css';

// TODO make this work with simplified item
const ImageGallery: React.FC<{
	className?: string;
	item?: Item;
	options: Record<string, string | undefined>;
}> = ({ className, item, options }) => {
	const images = useMemo(() => getImageWithOptions(item, options), [item, options]);
	if (item && !item.customFields) {
		console.log('no custom fields', item);
	}

	// State to hold the carousel API
	const [carouselApi, setCarouselApi] = React.useState<CarouselApi | null>(null);

	return (
		<div className={className}>
			{item ? (
				<>
					<Carousel opts={{ loop: true }} setApi={setCarouselApi}>
						<CarouselContent>
							{images.map((url, index) => (
								<CarouselItem key={index}>
									<img alt={item?.name ?? ''} className={styles.image} src={url} />
								</CarouselItem>
							))}
						</CarouselContent>
						<CarouselPrevious />
						<CarouselNext />
					</Carousel>
					<div className={styles.buttonContainer}>
						{images.map((image, index) => (
							<button
								className={styles.button}
								key={index}
								onClick={() => carouselApi?.scrollTo(index)} // Navigate to the corresponding slide
							>
								<img alt={item?.name} src={image} />
							</button>
						))}
					</div>
				</>
			) : (
				<IconLoader2 className={`${styles.loaderIcon} loader`} />
			)}
		</div>
	);
};

export default ImageGallery;
