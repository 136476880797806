export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const deepMerge = <T extends object>(object1: T, object2: Partial<T> | T): T => {
	if (typeof object1 !== 'object' || typeof object2 !== 'object') return object1;

	if (Array.isArray(object1)) {
		return object2 as T;
	}

	const result: any = {};

	for (const key in object1) {
		result[key] = object1[key];
	}

	for (const key in object2) {
		if (object2[key] !== null && typeof object2[key] === 'object') {
			result[key] =
				key in object1 && object1[key] !== null
					? // @ts-expect-error ts unsafe function
						deepMerge(object1[key], object2[key])
					: object2[key];
		} else {
			result[key] = object2[key];
		}
	}

	return result;
};
