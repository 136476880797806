import React from 'react';

import styles from './Skeleton.module.css';

export const Skeleton: React.FC<{
	className?: string;
	height?: number | string;
	lines?: number;
	width?: number | string;
}> = ({ className, height, lines, width }) => (
	<div className={`${styles.container} ${className ?? ''}`}>
		{Array.from({ length: lines ?? 1 }).map((_, index) => (
			<div className={styles.line} key={index} style={{ height, width }} />
		))}
	</div>
);

export default Skeleton;
