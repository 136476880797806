import { Link } from '@tanstack/react-router';
import React from 'react';

import Button from '@/components/Button/Button';
import { DialogTitle } from '@/components/Dialog/Dialog';
import ImageGallery from '@/components/ImageGallery/ImageGallery';
import { Item } from '@/customTypes/Item';

import styles from './QuickView.module.css';

// TODO we should merge part of this code with the PDP
export const QuickView: React.FC<{ item: Item; onClose?: () => void }> = ({ item, onClose }) => (
	<div>
		<DialogTitle>{item?.name}</DialogTitle>
		<div className={styles.qvWrapper}>
			<ImageGallery className={styles.qvCarousel} item={item} options={{}} />
			<div className={styles.qvContainer}>
				<Link
					className={styles.viewFullLink}
					onClick={onClose}
					params={{ itemURL: item?.url ?? '' }}
					search={{}}
					to="/item/$itemURL"
				>
					View full details
				</Link>
				<label className={styles.sku}>
					<span className={styles.skuLabel}>SKU: </span>
					{item?.itemId}
				</label>
				<span className={styles.productPrice}>{item?.priceFormated}</span>
				<Button>Add to Cart</Button>
			</div>
		</div>
	</div>
);

export default QuickView;
