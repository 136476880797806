import { IconMinus, IconPlus } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import styles from './NumberInput.module.css';
interface NumberInputProperties {
	className?: string;
	disabled?: boolean;
	max?: number;
	min?: number;
	setValue: React.Dispatch<React.SetStateAction<number>>;
	value: number;
}
const NUMBER_INTERVAL = 150;
const NumberInput: React.FC<NumberInputProperties> = ({
	className,
	disabled,
	max = Infinity,
	min = 0,
	setValue,
	value,
}) => {
	const [lessBeingClicked, setLessBeingClicked] = useState(false);
	const [moreBeingClicked, setMoreBeingClicked] = useState(false);
	// I'm sorry react gods
	const [forceRender, setForceRender] = useState(false);

	useEffect(() => {
		let interval: NodeJS.Timeout | undefined;
		const timeout = setTimeout(() => {
			if (lessBeingClicked) {
				interval = setInterval(() => {
					setValue(previousValue => {
						if (previousValue <= min) {
							clearInterval(interval);
							return previousValue;
						}
						return previousValue - 1;
					});
				}, NUMBER_INTERVAL);
			} else if (moreBeingClicked) {
				interval = setInterval(() => {
					setValue(previousValue => {
						if (previousValue >= max) {
							clearInterval(interval);
							return previousValue;
						}
						return previousValue + 1;
					});
				}, NUMBER_INTERVAL);
			}
		}, NUMBER_INTERVAL * 2);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [lessBeingClicked, moreBeingClicked, setValue, min, max]);

	return (
		<div className={`${styles.container} ${className}`}>
			<button
				aria-label="Decrease"
				className={styles.button}
				disabled={value <= min || disabled}
				onBlur={() => setLessBeingClicked(false)}
				onContextMenu={() => setLessBeingClicked(false)}
				onMouseDown={() => {
					setValue(value - 1);
					setLessBeingClicked(true);
				}}
				onMouseLeave={() => setLessBeingClicked(false)}
				onMouseUp={() => setLessBeingClicked(false)}
			>
				<IconMinus />
			</button>
			<input
				aria-label="Input"
				className={styles.input}
				disabled={disabled}
				onChange={event => {
					setValue(
						Math.min(Math.max(Number(event.target.value.replaceAll(/\D/g, '') || min), min), max),
					);
					setForceRender(!forceRender);
				}}
				onClick={event => {
					event.stopPropagation();
					event.preventDefault();
				}}
				onKeyDown={event => {
					if (
						!/^(?:\d|Backspace|Delete|ArrowLeft|ArrowRight)*$/.test(event.key) &&
						!event.ctrlKey
					) {
						event.preventDefault();
					}
				}}
				style={{ width: String(value).length + 'ch' }}
				type="text"
				value={value}
			/>
			<button
				aria-label="Increase"
				className={styles.button}
				disabled={value >= max || disabled}
				onContextMenu={() => setMoreBeingClicked(false)}
				onMouseDown={() => {
					setValue(value + 1);
					setMoreBeingClicked(true);
				}}
				onMouseLeave={() => setMoreBeingClicked(false)}
				onMouseUp={() => setMoreBeingClicked(false)}
			>
				<IconPlus />
			</button>
		</div>
	);
};

export default NumberInput;
