import React from 'react';

import { useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './Summary.module.css';

export const Summary: React.FC = () => {
	const summary = useOrderQuery(order => order?.summary);
	return (
		<div className={styles.container}>
			<h3 className={styles.summaryTitle}>Order Summary</h3>
			<div className={styles.summaryLine}>
				<span>Subtotal: </span>
				<span>{summary?.subtotal_formatted}</span>
			</div>
			<div className={styles.summaryLine}>
				<span>Shipping: </span>
				<span>{summary?.estimatedshipping_formatted}</span>
			</div>
			<div className={styles.summaryLine}>
				<span>Estimated Tax: </span>
				<span>{summary?.taxtotal_formatted}</span>
			</div>
			<div className={styles.summaryLine}>
				<span>Total: </span>
				<span>{summary?.total_formatted}</span>
			</div>
		</div>
	);
};

export default Summary;
