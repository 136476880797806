import * as React from 'react';

import ItemCarousel from '@/components/ItemCarousel/ItemCarousel';
import { Item } from '@/customTypes/Item';
import { useStateStore } from '@/stores';

const LastViewed: React.FC = () => {
	const lastViewed = useStateStore(store => store.state.lastViewed);

	return <ItemCarousel items={lastViewed as Item[]} />;
};

export default LastViewed;
